import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useLandingContext } from 'holded/lib/context/landingContext';
import getStrapiMedia from 'holded/lib/media';
import { GlobalData, Metadata, Page, getPath } from 'holded/modules/cms/domain/page';

type Props = {
  pageData: Page;
  globalData: GlobalData;
  metadata?: Metadata;
  canonical?: boolean;
};

const Seo = ({ pageData, metadata, canonical = true, globalData }: Props) => {
  const router = useRouter();
  const { defaultLocale } = useLandingContext();
  const data = metadata ? metadata : pageData?.attributes?.metadata;
  const locale = pageData.attributes?.locale ?? defaultLocale;
  const localizationSlugs = pageData.localizationSlugs;
  //const isDetailPath = isDetailPage(pageData.attributes?.slug ?? '');
  const path = pageData.isDetailPage
    ? router.asPath
    : getPath({
        defaultLocale,
        slug: pageData.attributes?.slug ?? '',
        locale: pageData.attributes?.locale ?? defaultLocale,
      });

  const sharedSocialMediaImageUrl = metadata?.shareMedia?.attributes
    ? getStrapiMedia(metadata?.shareMedia?.attributes?.url)
    : 'https://storage.googleapis.com/epicweb-holded-com/assets/jpg/holded-shared-social-media-image-essPCkXDm98yWjzT9Zk4yc.jpg';

  useEffect(() => {
    if (!locale || locale === document.documentElement.lang) return;
    document.documentElement.lang = locale;
  }, [locale]);

  return (
    <Head>
      <title key="title">{data?.metaTitle}</title>
      {data?.metaTitle && <meta property="og:title" content={data?.metaTitle} />}
      {data?.metaDescription && (
        <>
          <meta name={'description'} content={data?.metaDescription} key={'description'} />
          <meta property="og:description" content={data?.metaDescription} />
        </>
      )}

      {(data?.preventIndexing !== false || process.env.NODE_ENV === 'development') && (
        <>
          <meta name="robots" content="noindex,nofollow" />
          <meta name="googlebot" content="noindex,nofollow" />
        </>
      )}

      {globalData.global?.attributes?.favicon?.data?.attributes?.url && (
        <link
          rel={'shortcut icon'}
          href={getStrapiMedia(globalData.global?.attributes?.favicon?.data?.attributes?.url)}
          key="favicon"
        />
      )}

      {canonical && <link rel={'canonical'} href={`${process.env.NEXT_PUBLIC_URL}${path}`} />}

      {localizationSlugs &&
        localizationSlugs.length > 1 &&
        localizationSlugs?.map((localizationSlug) => {
          return (
            <link
              rel={'alternate'}
              hrefLang={localizationSlug.locale}
              href={`${process.env.NEXT_PUBLIC_URL}${localizationSlug.slug === '/' ? '' : localizationSlug.slug}`}
              key={localizationSlug.locale}
            />
          );
        })}

      {data?.keywords && <meta name={'keywords'} content={data?.keywords} />}

      <meta property={'og:image'} content={sharedSocialMediaImageUrl} key={'og:image'} />
    </Head>
  );
};

export default Seo;
